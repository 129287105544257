var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notfound_container" }, [
    _c("div", { staticClass: "wrapper" }, [
      _c("img", {
        attrs: { src: require("./imgs/notfound_icon.png"), alt: "" }
      }),
      _c("div", { staticClass: "wrapper_text" }, [
        _vm._v(" 暂未找到该商品的优惠信息，查找更多优惠信息请 ")
      ]),
      _c(
        "div",
        {
          staticClass: "btn",
          on: {
            click: function() {
              return _vm.$router.push("/")
            }
          }
        },
        [_vm._v("返回首页")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }