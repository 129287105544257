<template>
  <div class="notfound_container">
    <div class="wrapper">
      <img src="./imgs/notfound_icon.png" alt="" />
      <div class="wrapper_text">
        暂未找到该商品的优惠信息，查找更多优惠信息请
      </div>
      <div class="btn" @click="() => $router.push('/')">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.notfound_container {
  .wrapper {
    text-align: center;
    padding-bottom: 113px;
    img {
      margin-top: 106px;
      width: 115px;
      margin-bottom: 24px;
    }
    .wrapper_text {
      font-family: MicrosoftYaHei;
      font-size: 15px;
      color: #666666;
      margin-bottom: 24px;
    }
    .btn {
      cursor: pointer;
      width: 120px;
      height: 36px;
      margin: 0 auto;
      background: #ff2e54;
      border-radius: 2px;
      color: #fff;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      line-height: 36px;
    }
  }
}
</style>